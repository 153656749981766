<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="items"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            API Apps

            <v-spacer />

            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
            />

            <v-spacer />

            <v-btn
              :to="{ name: 'apiAppsNew' }"
              color="primary"
            >
              Voeg toe
            </v-btn>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredApiApps"
            :items-per-page="20"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
            must-sort
            :sort-by.sync="tableSortBy"
            :sort-desc.sync="tableSortDesc"
          >
            <template #[`item.externalUuid`]="{ item }">
              <router-link
                :to="{ name: 'apiAppsShow', params: { id: item._id } }"
              >
                {{ item.externalUuid }}
              </router-link>
            </template>

            <template #[`item.actions`]="{ item }">
              <wolk-delete-button
                question="
                  De API key kan dan niet meer gebruikt worden.
                  Dit kan eventueel het systeem van klanten blokkeren.
                "
                @confirmed="deleteApiApp(item)"
              />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import WolkDeleteButton from '../components/WolkDeleteButton.vue';

export default {
  components: {
    WolkDeleteButton,
  },
  data() {
    return {
      filter: '',
      headers: [
        { text: 'ID', align: 'start', value: 'externalUuid' },
        { text: 'Organisatie', align: 'start', value: 'organization.title' },
        {
          text: 'Acties',
          align: 'start',
          value: 'actions',
          sortable: false,
        },
      ],
      apiApps: [],
      items: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'API Apps',
          disabled: true,
          exact: true,
          to: { name: 'apiApps' },
        },
      ],
      tableSortBy: 'title',
      tableSortDesc: false,
    };
  },
  computed: {
    filteredApiApps() {
      return this.apiApps;
    },
  },
  beforeMount() {
    this.loadApiApps();
  },
  methods: {
    reload() {
      this.loadApiApps();
    },
    loadApiApps() {
      const url = `${config.VUE_APP_API_BASE_URL}/api-apps`;

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          this.apiApps = response.data.apiApps.map((apiApp) => ({
            ...apiApp,
            organization: response
              .data
              .organizations
              .find((o) => o._id === apiApp.references.organizationId),
          }));
        });
    },
    deleteApiApp(apiApp) {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/api-apps/${apiApp._id}`;

      axios({
        method: 'delete',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .catch((error) => {
          if (error && error.response && error.response.status === 422) {
            this.$store.commit('app/snackbarText', {
              text: 'Kan de API app niet verwijderen.',
              visible: true,
            });
          }
        })
        .finally(() => {
          this.loading = false;

          this.loadApiApps();
        });
    },
  },
};
</script>
