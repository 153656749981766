<template>
  <div class="wolk-delete-button">
    <v-icon
      class="mr-2"
      @click.stop="dialogOpen = true"
    >
      mdi-delete
    </v-icon>

    <v-dialog
      v-model="dialogOpen"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Weet je het zeker?
        </v-card-title>

        <v-card-text>
          {{ question }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="reject"
          >
            Nee
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirm"
          >
            Ja
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'WolkDeleteButton',
  props: {
    question: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogOpen: false,
    };
  },
  methods: {
    confirm() {
      this.$emit('confirmed');
      this.dialogOpen = false;
    },
    reject() {
      this.$emit('rejected');
      this.dialogOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .wolk-delete-button {
    display: inline-block;
  }
</style>
